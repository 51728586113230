html {
  font-family: Lato, sans-serif;
  font-size: 16px;
}

body {
  margin: 0;
  padding: 0;
}

#root {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  display: flex;
}

pre {
  display: inline;
}

.react-datepicker__close-icon:after {
  color: #fff !important;
  background-color: #8c9bad !important;
  border-radius: 50% !important;
  width: 18px !important;
  height: 16px !important;
  font-size: 14px !important;
}

.spud-autocomplete .spud-autocomplete__option.spud-autocomplete__option--is-focused {
  background-color: #0000;
}

.spud-autocomplete .spud-autocomplete__option.spud-autocomplete__option--is-selected {
  background-color: #2684ff;
}

.spud-autocomplete .spud-autocomplete__option.spud-autocomplete__option--is-focused:hover {
  color: #000;
  background-color: #deebff;
}
/*# sourceMappingURL=index.ad3bc57a.css.map */
